import { isNumber } from '../utils';

const { navigator } = window;
const { Cypress } = window as any;
export const isCypress = Boolean(Cypress);
export const isCypressComponentTest = Boolean(
  Cypress?.originalConfig?.componentTesting,
);
export const isMac = navigator.platform.indexOf('Mac') > -1;
export const isWindows = navigator.platform.indexOf('Win') > -1;
export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent,
);

export const isIPAD: boolean = (() => {
  return (
    navigator.maxTouchPoints > 1 &&
    // @ts-expect-error not supoported syntaxes yet
    /Mac/.test(navigator?.userAgentData?.platform || navigator.userAgent) &&
    !window.matchMedia('(pointer: fine) and (hover: hover)').matches
  );
})();

export const isIOS: boolean = (() => {
  return (
    isIPAD ||
    /iPhone|iPod/.test(
      // @ts-expect-error not supoported syntaxes yet
      navigator?.userAgentData?.platform || navigator.userAgent,
    )
  );
})();

const checkIsSafari15 = () => {
  if (!isIOS) return false;
  const res = /version\/([\w.]+) .*mobile\/\w+ (safari)/i.exec(
    navigator.userAgent,
  );
  if (res == null) return false;
  return Array.isArray(res) ? Number.parseInt(res[1], 10) >= 15 : false;
};
export const isSafariIOS15 = checkIsSafari15();

export const COMMAND = isMac ? '⌘' : 'ctrl';
export const ALT = isMac ? '⌥' : 'alt';

export const canTrack = navigator.doNotTrack !== '1';

export const isDarkOS = window.matchMedia?.(
  '(prefers-color-scheme: dark)',
).matches;

// Returns a boolean indicating whether the browser is running in standalone mode. Available on Apple's iOS Safari only.
export const isStandaloneWebApp = Boolean(
  isSafari && 'standalone' in navigator && (navigator as any).standalone,
);
// export const isStandaloneWebApp = true;

// eslint-disable-next-line import/no-mutable-exports
export let isTouchDevice =
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  ('msMaxTouchPoints' in navigator &&
    isNumber(navigator.msMaxTouchPoints) &&
    navigator.msMaxTouchPoints > 0);

export const forceSetIsTouchDevice = (value: boolean) => {
  isTouchDevice = value;
};
