export * from './apiTypes';
const { Cypress } = (typeof window === 'undefined' ? {} : window) as any;
const isCypress = Boolean(Cypress);

export const PUBLIC_API = 'https://api.boardist.io';
let INT_API_URL = (Cypress && Cypress.env('api')) || PUBLIC_API;

if (process.env.NODE_ENV === 'development') {
  if (!isCypress) {
    // INT_API_URL = 'http://localhost:9922/';
    INT_API_URL = 'http://localhost:3030';
    INT_API_URL = 'http://192.168.1.67:3030';
  }
}

export const API_URL = INT_API_URL;

let INT_UI_URL = 'https://boardist.io';
if (process.env.NODE_ENV === 'development') {
  INT_UI_URL = 'http://localhost:3000';
  // INT_UI_URL = 'http://192.168.1.197:3000';
}

export const UI_URL = INT_UI_URL;

export const authStorageKey = 'boardist-token';
export const contactEmail = 'boardist@protonmail.com';
export const chromeLink =
  'https://chrome.google.com/webstore/detail/boardist-sync-extension/oklkocdpbpkmoeepenloempjknbhaaal';

export const firefoxLink =
  'https://addons.mozilla.org/en-US/firefox/addon/boardist/';

export const social = {
  twitter: 'https://twitter.com/boardistapp',
};
